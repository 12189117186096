<template>
    <div v-if="show">
        <div class="card" v-show="operation !== 'detail'">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">{{$t('title.spotLoads')}}</template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-3">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('spotloadsownview')">
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>

                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="supplier-spot-table">
                    <b-table hover responsive
                             ref="table"
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(supplier_carrier_id)="record">
                            {{record.item.supplier_carrier ? record.item.supplier_carrier.name : ''}}
                        </template>
                        <template v-slot:cell(transport_vehicle_id)="record">
                            <span v-if="record.item.transport_vehicle">
                                <span v-if="record.item.transport_vehicle.truck_plate_number"> {{ record.item.transport_vehicle.truck_plate_number }} </span>
                                <span v-if="record.item.transport_vehicle.trailer_plate_number"> | {{ record.item.transport_vehicle.trailer_plate_number }} </span>
                            </span>
                        </template>
                        <template v-slot:cell(driver_id)="record">
                            {{record.item.driver ? record.item.driver.name : ''}}
                        </template>
                        <template v-slot:cell(status)="record">
                            <re-status :status="record.item.status"></re-status>
                        </template>
                        <template v-slot:cell(action)="record">
                            <div v-if="$global.hasRole('suppliercarrier')">
                                <a @click="handleAcceptClick(record.item)"
                                   v-if="record.item.status <= 2" :title="$t('button.title.acceptLoad')"
                                   v-b-tooltip.hover class="text-success">
                                    <i class="fe fe-check-square"></i>
                                </a>
                                <a-popconfirm title="Are you sure？"
                                              @confirm="handleSpotLoadResetClick(record.item)"
                                              v-if="record.item.status === 4 && !record.item.had_reset && !record.item.edited">
                                    <a :title="$t('button.title.reset')"
                                       v-b-tooltip.hover class="text-danger ml-2">
                                        <i class="fe fe-refresh-ccw"></i>
                                    </a>
                                </a-popconfirm>
                                <a @click="handleAcceptedEditClick(record.item)"
                                   v-if="record.item.status >= 4 && record.item.status < 6 && !record.item.edited"
                                   :title="$t('button.title.editItem')"
                                   v-b-tooltip.hover class="ml-2">
                                    <i class="fe fe-edit"></i>
                                </a>
                                <a @click="handleRejectClick(record.item)"
                                   v-if="record.item.status <= 2" :title="$t('button.title.rejectLoad')"
                                   v-b-tooltip.hover class="ml-2 text-danger">
                                    <i class="fe fe-x-square"></i>
                                </a>
                                <a @click="handleInTransitClick(record.item)"
                                   v-if="record.item.status >=4 && record.item.status <= 5"
                                   :title="$t('button.title.inTransit')"
                                   v-b-tooltip.hover class="ml-2 text-success">
                                    <i class="fe fe-truck"></i>
                                </a>
                                <a @click="handleUnLoadClick(record.item)"
                                   v-if="record.item.status == 6 && !record.item.is_d_unloaded"
                                   :title="$t('button.title.confirmUnload')" v-b-tooltip.hover
                                   class="ml-2 text-warning">
                                    <i class="fe fe-truck"></i>
                                </a>
                                <a :title="$t('button.title.requestToProceedUnload')"
                                   v-if="record.item.is_d_unloaded"
                                   class="ml-2 text-primary">
                                    <i class="fe fe-fast-forward" aria-hidden="true"></i>
                                </a>
                                <a @click="setOperation('detail', record.item.id)"
                                   :title="$t('button.title.detailItem')" class=" ml-2"
                                   v-b-tooltip.hover>
                                    <i class="icmn-info"></i>
                                </a>
                            </div>
                        </template>
                        <template v-slot:row-details="{ item }">
                            <b-card>
                                <b-list-group flush>
                                    <b-list-group-item></b-list-group-item>
                                </b-list-group>
                            </b-card>
                        </template>
                    </b-table><!-- /.b-table -->

                    <div class="clearfix">
                        <div class="float-left ">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]"
                                           size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                v-model="pagination.current"
                                :total-rows="pagination.total"
                                :per-page="pagination.perPage"
                                :first-text="$t('paginations.first')"
                                :prev-text="$t('paginations.prev')"
                                :next-text="$t('paginations.next')"
                                :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.supplier-spot-table-->
                <div class="supplier-spot-operation">
                    <a-drawer
                        placement="right"
                        :width="'90%'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="handleOperationReset"
                        :visible="selectedLoad !== null"
                        :zIndex="10"
                    >
                        <template slot="title">
                            {{title}}
                            <span class="text-dark" v-if="selectedLoad">
                                <span class="badge badge-dark">
                                    <strong>{{(selectedLoad || {}).load_number}}</strong>
                                </span>
                            </span>
                        </template>
                        <form @submit.prevent="handleSubmit" autocomplete="off">
                            <b-row class="mb-3">
                                <b-col md="3" lg="3" sm="12" v-if="formFields.status === 4">
                                    <div class="form-group">
                                        <div class="clearfix">
                                            <span class="pull-left">
                                                <label for="driver_id" class="d-block">
                                                    {{$t('input.driver')}} *
                                                </label>
                                            </span><!-- /.pull-left -->
                                            <span class="pull-right">
                                                <quick-driver-form
                                                    allow-update="1"
                                                    allow-create="1"
                                                    :id="formFields.driver_id"
                                                    :after-create="handleAfterQuickDriverCreated"
                                                    :after-update="handleAfterQuickDriverUpdated">
                                                </quick-driver-form>
                                            </span><!-- /.pull-right -->
                                        </div><!-- /.clearfix -->
                                        <treeselect
                                            :multiple="false"
                                            :options="dropdowns.drivers"
                                            placeholder=""
                                            v-model="formFields.driver_id"
                                            :class="[{'invalid is-invalid': (formErrors.has('driver_id'))}]"
                                        />
                                        <div class="invalid-feedback">{{formErrors.first('driver_id')}}
                                        </div>
                                    </div><!-- /.form-group -->
                                </b-col>
                                <b-col md="3" lg="3" sm="12" v-if="formFields.status === 4">
                                    <div class="form-group">
                                        <div class="clearfix">
                                            <span class="pull-left">
                                                <label for="transport_vehicle_id" class="d-block">
                                                    {{$t('input.transportVehicle')}} *
                                                </label>
                                            </span><!-- /.pull-left -->
                                            <span class="pull-right">
                                            <quick-transport-vehicle-form
                                                allow-update="1"
                                                allow-create="1"
                                                :id="formFields.transport_vehicle_id"
                                                :after-create="handleAfterQuickTransportVehicleCreated"
                                                :after-update="handleAfterQuickTransportVehicleUpdated">
                                            </quick-transport-vehicle-form>
                                        </span><!-- /.pull-right -->
                                        </div><!-- /.clearfix -->
                                        <treeselect
                                            :multiple="false"
                                            :options="dropdowns.transportVehicles"
                                            placeholder=""
                                            v-model="formFields.transport_vehicle_id"
                                            :class="[{'invalid is-invalid': (formErrors.has('transport_vehicle_id'))}]"
                                        />
                                        <div class="invalid-feedback">
                                            {{formErrors.first('transport_vehicle_id')}}
                                        </div>
                                    </div><!-- /.form-group -->
                                </b-col>
                                <b-col md="3" lg="3" sm="12" v-if="formFields.status === 4">
                                    <b-form-group
                                        :label="$t('input.estimatedLoadingTime')+' *'"
                                        label-for="driver_id"
                                        :class="{'has-error': formErrors.has('estimated_loading_time')}">
                                        <a-date-picker
                                            :show-time="{ format: 'HH:mm' }"
                                            placeholder=""
                                            format="DD-MM-YYYY hh:mm A"
                                            v-model="formFields.estimated_loading_time"
                                        />
                                        <div class="invalid-feedback">{{formErrors.first('estimated_loading_time')}}
                                        </div>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col md="3" lg="3" sm="12" v-if="formFields.status === 4">
                                    <b-form-group
                                        :label="$t('input.estimatedUnloadingTime')"
                                        label-for="estimatedUnloadingTime"
                                        :invalid-feedback="formErrors.first('estimated_unloading_time')">
                                        <a-date-picker
                                            :show-time="{ format: 'HH:mm' }"
                                            placeholder=""
                                            format="DD-MM-YYYY hh:mm A"
                                            v-model="formFields.estimated_unloading_time"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col md="3" lg="3" sm="12" v-if="formFields.status >= 6">
                                    <b-form-group
                                        :label="$t('input.dateTime')"
                                        label-for="dateTime"
                                        :class="{'has-error': formErrors.has('created_at')}">
                                        <a-date-picker
                                            :show-time="{ format: 'HH:mm' }"
                                            placeholder=""
                                            format="DD-MM-YYYY hh:mm A"
                                            v-model="formFields.created_at"
                                            :class="[{'invalid is-invalid': (formErrors.has('created_at'))}]"
                                        />
                                        <div class="invalid-feedback">{{formErrors.first('created_at')}}</div>
                                    </b-form-group>
                                </b-col>

                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.comment')+(formFields.status === 3 ? ' *' : '')"
                                        label-for="comment"
                                        :invalid-feedback="formErrors.first('comments')">
                                        <b-textarea
                                            id="comment"
                                            v-model="formFields.comments"
                                            type="text"
                                            :state="((formErrors.has('comments') ? false : null))"
                                            @focus="$event.target.select()"
                                        ></b-textarea>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="12" v-if="selectedLoad && selectedLoad.spot_load_orders">
                                    <h4 class="text-secondary">
                                        {{$t('title.orders')}}
                                    </h4>
                                    <h6>
                                        <span class="text-secondary" v-if="selectedLoad.spot_load_orders[0]">
                                            <span v-if="selectedLoad.spot_load_orders[0].from_location.city">{{selectedLoad.spot_load_orders[0].from_location.city}}, </span>
                                            (<span v-if="selectedLoad.spot_load_orders[0].from_location.country">{{selectedLoad.spot_load_orders[0].from_location.country}}</span>)
                                        </span> -
                                        <span class="text-secondary" v-if="selectedLoad.spot_load_orders[0]">
                                            <span v-if="selectedLoad.spot_load_orders[0].to_location.city">{{selectedLoad.spot_load_orders[0].to_location.city}}, </span>
                                            (<span v-if="selectedLoad.spot_load_orders[0].to_location.country">{{selectedLoad.spot_load_orders[0].to_location.country}}</span>)
                                        </span>
                                    </h6>
                                    <div class="table-responsive">
                                        <table class="table table-hover">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>{{$t('column.brand')}}</th>
                                                <th>{{$t('column.model')}}</th>
                                                <th>{{$t('column.orderId')}}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(ilo, index) in selectedLoad.spot_load_orders">
                                                <td>{{index + 1}}</td>
                                                <td>{{ilo.order.brand.title}}</td>
                                                <td>{{ilo.order.model.title}}</td>
                                                <td>{{ilo.order.order_id}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-col>
                            </b-row>
                            <div class="drawer-footer">
                                <b-button variant="warning" @click="handleOperationReset" class="mr-2"
                                          size="sm" :title="$t('button.title.cancel')" v-b-tooltip.hover>
                                    {{$t('button.cancel')}}
                                </b-button>
                                <b-button variant="primary" button="submit" type="filled"
                                          :disabled="global.pendingRequests > 0" size="sm"
                                          :title="$t('button.title.update')" v-b-tooltip.hover>
                                    <clip-loader style="display: inline" :loading="true" color="#fff"
                                                 size="12px"
                                                 v-if="global.pendingRequests > 0"></clip-loader>
                                    {{$t('button.update')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!--/.supplier-spot-operation-->
                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.brands')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.brands"
                                            placeholder=""
                                            v-model="filters.brands"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.models')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.models"
                                            placeholder=""
                                            v-model="filters.models"
                                            @input="handleSelectModelsSelect"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.code')">
                                        <treeselect
                                            :multiple="true"
                                            :options=" _.uniqBy(_.filter(dropdowns.configCode, ({ model_id }) => _.every([_.includes(filters.models, model_id)])),'id')"
                                            placeholder=""
                                            v-model="filters.configCode"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.loadingPoint')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.loadingPoint"
                                            placeholder=""
                                            v-model="filters.loadingPoint"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.unloadingPoint')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.unloadingPoint"
                                            placeholder=""
                                            v-model="filters.unloadingPoint"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.drivers')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.drivers"
                                            placeholder=""
                                            v-model="filters.drivers"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.vehicles')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.vehicles"
                                            placeholder=""
                                            v-model="filters.vehicles"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.status')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.status"
                                            placeholder=""
                                            v-model="filters.status"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.fromAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.toAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{$t('button.close')}}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{$t('button.reset')}}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{$t('button.apply')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->
            </div><!-- /.card-body-->
        </div><!-- /.card -->
        <div v-if="operation === 'detail'">
            <detail :handle-close-operation="handleOperationClose"></detail>
        </div>
    </div>
</template>
<script>
    import ListingMixin from '../../../util/ListingMixin'
    import Error from '../../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import StatusUpdateMixin from './StatusUpdateMixin'
    import Detail from './detail'
    import QuickDriverForm from "./../../drivers/QuickDriverForm"
    import QuickTransportVehicleForm from "./../../transportVehicles/QuickTransportVehicleForm"

    const FORM_STATE = {
        id: null,
        driver_id: null,
        transport_vehicle_id: null,
        comments: null,
        created_at: null,
        edit: 0,
        estimated_loading_time: null,
        estimated_unloading_time: null,
        status: null,
        _method: 'post',
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
        brands: [],
        models: [],
        configCode: [],
        drivers: [],
        vehicles: [],
        status: [],
        loadingPoint: [],
        unloadingPoint: [],
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.loadNumber'),
            key: 'load_number',
            sortable: true,
        },
        {
            label: self.$t('column.transportVehicle'),
            key: 'transport_vehicle_id',
            sortable: true,
        },
        {
            label: self.$t('column.driver'),
            key: 'driver_id',
            sortable: true,
        },
        {
            label: self.$t('column.qty'),
            key: 'qty',
            sortable: false,
        },
        {
            label: self.$t('column.status'),
            key: 'status',
            sortable: true,
        },
        (self.$global.hasAnyPermission(['spotloadsownview'])
            ? {
                label: self.$t('column.action'),
                class: 'text-right',
                key: 'action',
                width: 150,
            } : {}),
    ];

    export default {
        mixins: [ListingMixin, StatusUpdateMixin],
        components: {
            Treeselect,
            Detail,
            QuickDriverForm,
            QuickTransportVehicleForm
        },
        data() {
            return {
                operationTitle: 'title.spotLoad',
                formFields: {...FORM_STATE},
                filters: {...FILTER_STATE},
                listUrl: 'spot/loads/carrier/supplier',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    transportVehicles: [],
                    suppliers: [],
                    orders: [],
                    countries: [],
                    brands: [],
                    models: [],
                    configCode: [],
                    drivers: [],
                    vehicles: [],
                    status: [
                        {id: 1, label: this.$t('title.transportOrder')},
                        {id: 2, label: this.$t('title.announced')},
                        {id: 3, label: this.$t('title.rejected')},
                        {id: 4, label: this.$t('title.confirmed')},
                        {id: 5, label: this.$t('title.pickUp')},
                        {id: 6, label: this.$t('title.stockExitInTransit')},
                        {id: 7, label: this.$t('title.deliveryRequested')},
                        {id: 8, label: this.$t('title.delivered')},
                    ],
                    loadingPoint: [],
                    unloadingPoint: [],
                },
                show: true,
                selectedLoad: null,
            }
        },
        mounted() {
            this.getTransportVehicles();
            this.getDrivers();
            this.getBrands();
            this.getModels();
            this.getLocations();
            this.getCountries();
            this.$title = this.$t('topBar.navigations.modules.spot')
        },
        methods: {
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addOrder' : 'title.editOrder')
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {
                this.formErrors = new Error({})
                const {estimated_unloading_time, estimated_loading_time, created_at} = this.formFields
                try {
                    const response = await request({
                        url: '/spot/loads/carrier/supplier/update',
                        method: 'post',
                        data: {
                            ...this.formFields,
                            estimated_unloading_time: ((estimated_unloading_time) ? this.$global.dateToUtcDate(estimated_unloading_time.format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD hh:mm A', 'YYYY-MM-DD HH:mm:ss') : null),
                            estimated_loading_time: ((estimated_loading_time) ? this.$global.dateToUtcDate(estimated_loading_time.format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD hh:mm A', 'YYYY-MM-DD HH:mm:ss') : null),
                            created_at: ((created_at) ? this.$global.dateToUtcDate(created_at.format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD hh:mm A', 'YYYY-MM-DD HH:mm:ss') : null),
                        },
                    })

                    this.itemUpdated()
                    this.handleOperationReset()
                    this.loadList()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            handleOperationReset() {
                this.formFields = {...FORM_STATE}
                this.selectedLoad = null
            },
            hasListAccess() {
                return this.$global.hasPermission('spotloadsownview')
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            async getCountries() {
                try {
                    const response = await request({
                        url: '/dropdowns/countries',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.countries = data

                } catch (e) {
                    this.dropdowns.countries = []
                }
            },
            async getBrands() {
                try {
                    const response = await request({
                        url: '/dropdowns/brands',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.brands = data

                } catch (e) {
                    this.dropdowns.brands = []
                }
            },
            async getModels() {
                try {
                    const response = await request({
                        url: '/dropdowns/models',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.models = data.map(item => ({id: item.id, label: `${item.label}`}))
                    this.dropdowns.configCode = data.map(item => ({
                        id: item.version_code,
                        label: item.version_code,
                        model_id: item.id
                    }))
                } catch (e) {
                    this.dropdowns.models = []
                    this.dropdowns.configCode = []
                }
            },
            async getDrivers() {
                try {
                    const response = await request({
                        url: '/dropdowns/drivers',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.drivers = data
                } catch (e) {
                    this.dropdowns.drivers = []
                }
            },
            async getTransportVehicles() {
                try {
                    const response = await request({
                        url: '/dropdowns/transport/vehicles',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.vehicles = data
                    this.dropdowns.transportVehicles = data
                } catch (e) {
                    this.dropdowns.vehicles = []
                    this.dropdowns.transportVehicles = []
                }
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.loadingPoint = data
                    this.dropdowns.unloadingPoint = data
                } catch (e) {
                    this.dropdowns.loadingPoint = []
                    this.dropdowns.unloadingPoint = []
                }
            },
            handleSelectModelsSelect(changeValue) {
                this.filters.configCode = []
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
            },
            handleAfterQuickDriverCreated(inputs) {
                this.dropdowns.drivers.push({id: inputs.id, label: inputs.name})
                this.formFields.driver_id = inputs.id;
            },
            handleAfterQuickDriverUpdated(inputs) {
                const index = _.findIndex(this.dropdowns.drivers, {id: inputs.id})
                this.$set(this.dropdowns.drivers[index], 'label', `${inputs.name}`);
            },
            handleAfterQuickTransportVehicleCreated(inputs) {
                this.dropdowns.transportVehicles.push({
                    id: inputs.id,
                    label: inputs.brand + ', ' + inputs.truck_plate_number + " | " + inputs.trailer_plate_number
                })
                this.formFields.transport_vehicle_id = inputs.id;
            },
            handleAfterQuickTransportVehicleUpdated(inputs) {
                const index = _.findIndex(this.dropdowns.transportVehicles, {id: inputs.id})
                this.$set(this.dropdowns.transportVehicles[index], 'label', `${inputs.brand + ', ' + inputs.truck_plate_number + " | " + inputs.trailer_plate_number}`);
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss">
    .w-80 {
        width: 80px;
    }

    .in-center {
        position: relative;
        top: 1px;
        margin-right: 8px;
    }

    .order-select-chbx {
        position: absolute;
        right: -7px;
        top: -7px;
    }

    .add-load-btn {
        position: absolute;
        top: 12px;
        right: 50px;
    }

    @media screen and (max-width: 768px) {
        .ant-drawer-content-wrapper {
            width: 98% !important;
        }
    }

    [data-kit-theme="default"] .ant-input {
        height: 35px;
    }

    .ant-calendar-picker {
        width: 100%;
    }

    [data-kit-theme="default"] .ant-form-item-label {
        line-height: 26px !important;
    }

    .has-error .invalid-feedback {
        display: block;
    }
</style>
